<script setup>
import { ref, onMounted, computed } from 'vue';
import { useForm, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';

const props = defineProps({
    discipleship: Object,
    instructorDiscipleshipClasse: Object,
    members: Object,
});

const form = useForm({
    start_time: props.instructorDiscipleshipClasse.start_time_gross,
});

const selected = ref([]);

const frequencyMembers = ref(props.members);

onMounted(() => {
    selected.value = props.disciplinesClasse.map((discipline) => ({
        ...discipline.instructor_discipleship,
        start_time: discipline.start_time_gross,
    }));
});

const transformMembers = (members) => {
    return members.map((item) => ({
        id: item.id,
        status: item.status,
    }));
};

const update = () =>
    form
        .transform((data) => ({
            ...data,
            frequencies: transformMembers(frequencyMembers.value),
        }))
        .put(
            `/dashboard/discipleships/${props.discipleship.id}/modules/${props.instructorDiscipleshipClasse.discipleship_classe_id}/classes/${props.instructorDiscipleshipClasse.id}`
        );
</script>

<template>
    <Dashboard page-title="Editar aula">
        <Breadcrumb
            current-page-name="Editar aula"
            :link-list="[
                {
                    previous_page_url: `discipleships/${discipleship.id}}`,
                    previous_page_name: `${discipleship.name}`,
                },
                {
                    previous_page_url: `discipleships/${discipleship.id}/classes/${instructorDiscipleshipClasse.discipleship_classe_id}`,
                    previous_page_name: `Módulo`,
                },
            ]"
        />

        <div id="edit-classe">
            <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
                <div class="col-md-6">
                    <h4 class="font-bold m-0">
                        Editar aula de
                        {{ instructorDiscipleshipClasse.instructor_discipleship.discipline_name }}
                    </h4>
                </div>
                <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                    <Link
                        :href="`/dashboard/discipleships/${discipleship.id}/modules/${instructorDiscipleshipClasse.instructor_discipline_discipleship_id}/classes/${instructorDiscipleshipClasse.id}`"
                        class="btn btn-success btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                        Visualizar registro
                    </Link>
                </div>
            </div>

            <Form
                button-name="Editar dados"
                :method="update"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="col-md-12">
                    <label for="start_time" class="form-label"> Horário de início </label>

                    <input
                        id="start_time"
                        type="datetime-local"
                        autocomplete="off"
                        class="form-control"
                        v-model="form.start_time"
                        placeholder=""
                        required
                    />
                    <small v-if="form.errors.start_time" class="text-danger mt-3">
                        {{ form.errors.start_time }}
                    </small>
                </div>

                <hr v-if="frequencyMembers.length" />

                <div class="row" v-if="frequencyMembers.length">
                    <div class="col-12">
                        <legend>Frequência dos inscritos</legend>

                        <div class="list-group frequency-presence-members">
                            <div
                                v-for="(item, index) in frequencyMembers"
                                :key="index"
                                class="list-group-item font-semibold fs-12 p-3 mb-2 border rounded-3 d-flex justify-content-between align-items-center"
                            >
                                <span>
                                    <img
                                        class="me-2 rounded-circle"
                                        width="30px"
                                        :src="`${item.member.identification_photo}`"
                                        alt=""
                                    />
                                    {{ item.member.clean_name }}
                                </span>
                                <span>
                                    <input
                                        type="radio"
                                        class="btn-check"
                                        :name="`options-base-${item.id}`"
                                        :id="`options-base-${item.id}-present`"
                                        autocomplete="off"
                                        v-model="item.status"
                                        value="COMPLETED"
                                    />

                                    <label
                                        class="btn fs-10 btn-outline-success me-2"
                                        :for="`options-base-${item.id}-present`"
                                    >
                                        Presencial
                                    </label>

                                    <input
                                        type="radio"
                                        class="btn-check"
                                        :name="`options-base-${item.id}`"
                                        :id="`options-base-${item.id}-online`"
                                        autocomplete="off"
                                        v-model="item.status"
                                        value="COMPLETED_REMOTELY"
                                    />
                                    <label
                                        class="btn fs-10 btn-outline-primary me-2"
                                        :for="`options-base-${item.id}-online`"
                                    >
                                        Remoto
                                    </label>

                                    <input
                                        type="radio"
                                        class="btn-check"
                                        :name="`options-base-${item.id}`"
                                        :id="`options-base-${item.id}-not-completed`"
                                        autocomplete="off"
                                        v-model="item.status"
                                        value="NOT_COMPLETED"
                                    />
                                    <label
                                        class="btn fs-10 btn-outline-danger"
                                        :for="`options-base-${item.id}-not-completed`"
                                    >
                                        Ausente
                                    </label>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    </Dashboard>
</template>
